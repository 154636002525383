import { Popover, Box } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import IMGPofsis from '../../assets/images/logos/pofsis_no_border.png';
import ImgBiz from '../../assets/images/logos/biz_logo.png';
import ImgPsl from '../../assets/images/logos/psl_logo.png';
import { VM } from "../../core/VM";
import { domain } from "../../core/Env";
import { useState } from "react";

export const Navigation = ({ q }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const isMobile = VM() === '/devices/mobile';
    const fontSize = isMobile ? "14px !important" : "16px !important";

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={isMobile ? { pt: 3, pb: 4 } : { py: 12.5 }} width="100%" fontSize={fontSize}>
            <Box display="flex" alignItems="center" >
                <Box px={!isMobile ? 1 : .5} py={!isMobile ? .5 : "1.5px"} display="flex" alignItems="center"><img src={IMGPofsis} alt="POFSIS Logo" width={!isMobile ? "26" : "14"} /></Box>
                {!isMobile ?  <Box fontSize="24px" ml={3.5}><b>POF</b>SIS</Box> : <Box fontSize="16px" fontWeight="600" ml={1.5}>POFSIS</Box> }
            </Box>
            <Box display="flex" alignItems="center" px={isMobile? 2 : 3} py="12.5px" bgcolor="#29555F" borderRadius="6px" color="#FFF" fontWeight="600" className="c-pointer" onClick={handleClick}>
                <Box mr={3}>Sign in</Box>
                <KeyboardArrowDown />
            </Box>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        overflow: 'visible',
                        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
                        mt: 1,
                        width: 'auto',
                        minWidth: '245px',
                        borderRadius: '6px',
                        backgroundColor: '#FFF',
                        fontSize
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                {[
                    { logo: ImgBiz, label: "Business Sign in", url: domain('biz').url },
                    { logo: ImgPsl, label: "Personal Sign in", url: domain('psl').url },
                ].map((item, idx) => (
                    <a key={idx} href={item.url}>
                        <Box display="flex" alignItems="center" gap="12px" fontWeight="400" p={3} className="c-pointer">
                            <img src={item.logo} alt={`${item.label} Logo`} width="28px" />
                            <Box>{item.label}</Box>
                            <KeyboardArrowRight />
                        </Box>
                    </a>
                ))}
            </Popover>
        </Box>
    );
};


// ========return old version refactored ui/ux============//
// const useStyles = makeStyles({
//     button: {
//         borderRadius: '8px',
//     }
// });
// {/* <Box display="flex" justifyContent="space-between" alignItems="center" py={3} width="100%">
// <Box display="flex" alignItems="center">
//     <img src={IMGPofsis} alt="POFSIS LOGO NO BORDER" width="30"/>
//     {VM()==='/devices/mobile'?'':<Box fontSize={24} ml={1}><b>POF</b>SIS</Box>}
// </Box>
// <Stack direction="row" spacing={2}>
//     <a href={`${domain('psl').url}`}><Button variant="contained" className={`${classes.button}`} style={{backgroundColor: '#0070EF'}}>Personal Login <IArrowRightAlt style={{marginLeft: 5}} /></Button></a>
//     <a href={`${domain('biz').url}`}><Button variant="contained" className={`${classes.button}`} style={{backgroundColor: '#11783C'}}>Business Login<IArrowRightAlt style={{marginLeft: 5}} /></Button></a>
// </Stack>
// </Box> */}