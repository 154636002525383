import { useState } from "react"

export const SUStates = () => {
    const [step, setStep] = useState(1)
    const [va, setVa] = useState('')
    const [inputs, setInputs] = useState({account:{value: '', stat: false, msg: ''}, password:{value: '', stat: false, msg: '', toggle: false}})
    const [tpinputs, setTpinputs] = useState({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
    const [otp, setOtp] = useState({inputted: '', code: '', stat: false, msg: 'Enter verification code'})
    const [finalize, setFinalize] = useState({
        submitted: false,
        exist: null,
        step: 1,
        sainfo: null,
        inputs: {password:{value: '', stat: false, msg: '', toggle: false, strength: 5}, repass: {value: '', stat: false, msg: '', toggle: false, strength: 5}},
        btn: 'Submit',
        validated: {lc: false, uc: false, number: false, length: false, sc: false}
    })

    return {
        step: {data: step, set: setStep},
        va: {data: va, set: setVa},
        inputs: {data: inputs, set: setInputs},
        tpinputs: {data: tpinputs, set: setTpinputs},
        otp: {data: otp, set: setOtp},
        finalize: {data: finalize, set: setFinalize}
    }
}