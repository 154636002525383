import { Top } from './Top';
import { Bottom } from './Bottom';

const NavigationV2 = ({ side }) => {
    let Nav = [Top, Bottom]
    Nav = Nav[side]
    return (
        <Nav/>
    )
}

export default NavigationV2;