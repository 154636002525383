import { Container, ButtonBase } from "@mui/material"
import { Box } from "@mui/system"
import { Navigation } from "./Navigation"
import { makeStyles } from '@mui/styles';
import { VM } from "../../core/VM";
import { useLocation } from "react-router-dom";
import { domain } from "../../core/Env";
import { ArrowHeadRight, Bag, Suitcases } from "../../core/global/Icons";
import dkstpBg from "../../assets/images/core/bg.png"
import mblBg from "../../assets/images/core/bg_mobile.png"
import { HomeMobile } from "./devices/mobile/Index";

let view = VM()
const style = makeStyles(theme => ({
    button: {
        borderRadius: '8px'    
    },
    buttonws: { 
        borderRadius: '8px',
    },
    vidFrame: {
        borderRadius: '8px',
        width: '1220px',
        height: 'auto',
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: 'auto',
            position: 'relative',
            paddingBottom: '56.25%',
            overflow: 'hidden'
        }
    },
}));

export const Home = () => {
    const q = new URLSearchParams(useLocation().search);
    const classes = style();

    return view === '/devices/mobile' ? <HomeMobile /> :(
        <Box height="100%">
            <Container sx={{height: '100%'}} >
                <Box display="flex" flexDirection="column" alignItems="center" height="100%" >
                    <Navigation q={q} />
                    <Box mb={3} p={1} fontSize={VM()==='/devices/mobile'?'28px':'48px'} fontWeight={VM()==='/devices/mobile'?'700':'500'}>GROW YOUR CAREER & BUSINESS</Box>
                    <Box mb={4} fontSize={VM()==='/devices/mobile'?'16px':'28px'} fontWeight={VM()==='/devices/mobile'?'600':'700'}>10x FASTER, 10x HIGHER & 10x BIGGER!</Box>
                    <Box width="100%" display="flex" justifyContent="center" gap={3} alignItems="center" color="#292C2F" mb={9.5}>
                        <a href={`${domain('psl').ws}`}>
                            <Box component={ButtonBase} p={1.5} bgcolor="#FFF" className='c-pointer' sx={{textAlign: 'start', '&:hover': {backgroundColor: '#E8ECEE', '& > div:first-of-child': {bgcolor: '#C9D6DF',},},}} display="flex" justifyContent="space-between" alignItems="center" borderRadius="6px" boxShadow='0px 2px 4px 0px rgba(40, 55, 69, 0.08), 0px 0px 6px 0px rgba(40, 55, 69, 0.02)' width="496px" >
                                <Box p={1.5} display="flex" borderRadius="4px" bgcolor="#E3F2FD" mr={2}><Bag /></Box>
                                <Box mr={1.25}>
                                    <Box fontSize={14} fontWeight={600} mb={.5}>Launch your Career Potential</Box>
                                    <Box fontSize={12}>Boost your productivity and personal growth with our all-in-one tools.</Box>
                                </Box>
                                <Box><ArrowHeadRight /></Box>
                            </Box>
                        </a>
                        <a href={`${domain('biz').ws}`}>
                            <Box component={ButtonBase} p={1.5} bgcolor="#FFF" className='c-pointer' sx={{textAlign: 'start', '&:hover': {backgroundColor: '#E8ECEE', '& > div:first-of-child': {bgcolor: '#C9D6DF',},},}} display="flex" justifyContent="space-between" alignItems="center" borderRadius="6px" boxShadow='0px 2px 4px 0px rgba(40, 55, 69, 0.08), 0px 0px 6px 0px rgba(40, 55, 69, 0.02)' width="496px">
                                <Box p={1.5} display="flex" borderRadius="4px" bgcolor="#DAE8DA" mr={2}><Suitcases /></Box>
                                <Box mr={1.25}>
                                    <Box fontSize={14} fontWeight={600} mb={.5}>Empower your Business</Box>
                                    <Box fontSize={12}>Simplify project & tasks management, streamline administration and unlock growth.</Box>
                                </Box>
                                <Box><ArrowHeadRight /></Box>
                            </Box>
                        </a>
                    </Box>
                    <Box borderRadius="16px" py={.5} px={1.5} mb={2} bgcolor="#B0D8E1" fontSize="14px" fontWeight="500" lineHeight="20px">"Inspiring stories of extraordinary and heroic individuals in business and careers."</Box>
                    <Box pb="185px">
                        <Box className={classes.vidFrame}>
                            <iframe 
                                src="https://www.youtube.com/embed/JFVrXP1yz7M?autoplay=1&mute=1" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen 
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                            ></iframe>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

// ========return old version refactored ui/ux============//
// {/* <Box height="100%">
// <Container sx={{height: '100%'}}>
//     <Box display="flex" flexDirection="column" alignItems="center" height="100%">
//         <Navigation q={q} />
//         <Box textAlign="center">
//             <Box mb={1} fontSize={VM()==='/devices/mobile'?20:40}>Grow your <Box component="b" color="primary.personal">Career</Box> & <Box component="b" color="primary.business">Business</Box></Box>
//             <Box fontSize={VM()==='/devices/mobile'?16:24} fontWeight="900">10x Faster 10x Higher 10x Bigger</Box>
//             <Box display="flex" justifyContent="center" mt={1} mb={1}>
//             </Box>
//             <Box display="flex" justifyContent="center" p={1.5} bgcolor="#fff" borderRadius={4}>
//                 <iframe src="https://www.youtube.com/embed/JFVrXP1yz7M?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className={classes.vidFrame} ></iframe>
//             </Box>
//             <Box>
//               <Stack direction="row" spacing={2} justifyContent='center' py='15px'>
//                 <a href={`${domain('psl').ws}`}><Button variant="contained" className={`${classes.buttonws}`} style={{backgroundColor: '#0070EF'}} > 
//                 <Box py='4px'>
//                     <Box fontSize={14} lineHeight="16px">PERSONAL</Box> 
//                     <Box fontSize={12} lineHeight="14px">Yes, I want to Grow my Career </Box>
//                     <Box fontSize={10}>10x Faster 10x Higher 10x Bigger</Box>
//                 </Box>
//                 </Button></a>
//                 <a href={`${domain('biz').ws}`}><Button variant="contained" className={`${classes.buttonws}`} style={{backgroundColor: '#11783C'}} >
//                 <Box py='4px'>
//                     <Box fontSize={14} lineHeight="16px">BUSINESS </Box> 
//                     <Box fontSize={12} lineHeight="14px">Yes, I want to Grow my Business </Box>
//                     <Box fontSize={10}>10x Faster 10x Higher 10x Bigger</Box>
//                 </Box>
//                 </Button></a>
//                 </Stack>
//             </Box>
//         </Box>
//         <Box fontSize={14} mb={3}>pofsis © 2022 </Box>
//     </Box>
// </Container>
// </Box> */}