import { lazy, Suspense, useContext } from "react"
import { VM } from "../../core/VM"
import { pslstyle } from "../../core/styling/si"
import { ToolContext } from "../../core/context/ToolContext";
import { validateAccount } from "../../core/validator/Validator";
import { rqx } from '../../core/request/API';
import { env, domain } from '../../core/Env';
import { useHistory, useLocation } from 'react-router-dom';

const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))
const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const ToolSI = ({ match }) => {
    const { si_state } = useContext(ToolContext)
    const { step, va, inputs, tpinputs } = si_state
    const q = new URLSearchParams(useLocation().search);
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const history = useHistory()

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const resetPassHandler = () => {
        const sainfo = {
            va: va.data,
            default: window.btoa(`unauth_${inputs.data.account.value}`),
            rrf: 'tool_login'
        }

        history.push(`/${platform}/reset/password?from=${encodeURIComponent(q.get('from'))}&sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`)
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        
        // console.log(q.get('from'))
        // console.log(q.get('from') !== null ? __SESSION.tinfo[0].tid : 0)
        // return
        if (step.data!==10 && step.data!==20) {
            tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
            inputs.set({...inputs.data, account : {...inputs.data.account, stat: false, msg: ''}})
            // if (step.data===1) {
                step.set(10)
                if (inputs.data.account.value !== '') {
                    let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${va.data}`, {account: inputs.data.account.value, ref: 1, platform: platform, tool_auth: q.get('from') !== null ? __SESSION.tinfo[0].tid : 0})
                    if (attempt.msg === 'valid') {
                        step.set(2)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
                    } else if (attempt.msg === '!account') {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: `Account is not registered to ${__SESSION.tinfo[0].name}`}})
                        return
                    } else {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                        return
                    }
                } else {
                    step.set(1)
                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                    return
                }
            // } else {
                step.set(20)
                if (inputs.data.password.value !== '') {
                    const pass = inputs.data.password.value.includes('#') ? encodeURIComponent(inputs.data.password.value): inputs.data.password.value
                    let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${va.data}`, {account: inputs.data.account.value, password: pass, ref: 2, platform: platform})
                    if (attempt.msg === 'valid') {
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: false, msg: ''}})
                        
                        let url = platform==='psl'?domain('psl').url:domain('biz').url
                        if (q.get('from') !== null ) {
                            let subdir = `ainfo=${encodeURIComponent(JSON.stringify(attempt.ainfo))}`
                            url = `${__SESSION.cb}?${subdir}`
                        } else {
                            url = `${url}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(attempt.ainfo))}`
                        }
                        // console.log(url);
                        window.location.href = url
                    } else {
                        step.set(2)
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Incorrect password."}})
                        return
                    }
                } else {
                    step.set(2)
                    inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Password is required"}})
                    return
                }
            // }
        }
    }

    console.log(inputs)
    
    return (
        <Suspense fallback="">
            <Auth classes={classes} platform={platform} {...si_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} resetPassHandler={resetPassHandler} />
        </Suspense>
    )
}